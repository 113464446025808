  // 加锁弹出框
<template>
     <base-dialog
      :visible.sync="visibleState"
      :showFooter="false"
      title="加锁（锁定回款账户）"
      class="lock"
    >
    <formInfo
        :count="count"
        :componentList="formInfoConfig"
        :model="detailInfo"
      />
    <base-form
     :componentList="lockForm"
      :showBtns="false"
      :formAttrs="{
        model: formData,
        labelPosition: 'left',

      }"
      class="formStyle"
      ref="formStyle"
    ></base-form>
    <template slot="footer" >
       <base-button label="保存"  @click="submitLockFrom('save')"/>
     <base-button label="保存并锁定"  @click="submitLockFrom('submit')"/>
     <base-button label="关闭"  @click="cancel" type="default"/>
    </template>
    <!-- 预览弹窗 -->
     <!-- <pre-view-new :count="countPre" :fileId="formData.fileId" :fileType="fileType" ref='preView' /> -->
 <pre-view :fileId="fileData.fileId" :isOpen='true' :fileType="fileData.fileFileSuffix" :count="countPre" />

    </base-dialog>
</template>
<script>
// import PreViewNew from '@/components/pre-view-new/pre-view-new.vue'
import PreView from '@/components/pre-view/pre-view.vue'

import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form/base-form'
import FormInfo from '@/components/packages/form-info/form-info.vue'
import { formInfoConfig, lockForm } from '../utils/dialog-config'
import { lockAPi } from '@/api/assetApi'

export default {
  props: {
    visible: Boolean,
    contractId: String
  },
  data () {
    return {
      count: 0,
      countPre: 0,
      title: '新增签章人',
      visibleSelectAccount: false, // 选择用户
      formData: {}, // 表数据
      disabled: false, // 禁用按钮
      enterpriseId: '', // 供应商id
      fileName: '', // 文件名
      fileId: '', // 文件id
      fileType: '', // 文件类型
      detailInfo: {}, // 合同信息展示数据
      previsible: false, // 预览弹窗
      fileData: {}
    }
  },
  components: { BaseDialog, BaseButton, BaseForm, FormInfo, PreView },
  computed: {
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
        if (!val) {
          this.$nextTick(() => {
            this.disabled = false
            this.$refs.formStyle.resetFields()
          })
        }
      }
    },
    lockForm () {
      return lockForm(this)
    },
    formInfoConfig () {
      return formInfoConfig(this)
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.formData = {}
        this.getLockData()
      }
    }
  },
  methods: {
    // 选择账户回显
    selectAccount (data) {
      if (data) {
        this.$set(this.formData, 'account', data.account)
        this.$set(this.formData, 'bankName', data.bankName)
        this.$set(this.formData, 'accountNo', data.accountNo)
        this.$set(this.formData, 'accountId', data.keyId)
        this.$set(this.formData, 'enterpriseId', data.enterpriseId)
      } else {
        this.$set(this.formData, 'account', '')
        this.$set(this.formData, 'bankName', '')
        this.$set(this.formData, 'accountNo', '')
        this.$set(this.formData, 'accountId', '')
        this.$set(this.formData, 'enterpriseId', '')
      }
    },
    // 上传附件
    uploadFile (data) {
      if (data) {
        this.$set(this.formData, 'fileName', data.fileName)
        this.$set(this.formData, 'fileId', data.keyId)
      } else {
        this.$set(this.formData, 'fileName', '')
        this.$set(this.formData, 'fileId', '')
      }
    },
    // 删除文件
    delFile (data) {
      this.$set(this.formData, 'fileName', '')
      this.$set(this.formData, 'fileId', '')
    },
    preview () {
      const fileSuffix = this.formData.fileName ? this.formData.fileName.split('.') : []
      this.fileType = fileSuffix[fileSuffix.length - 1]
      this.fileData.fileId = this.formData.fileId
      this.fileData.fileFileSuffix = fileSuffix[fileSuffix.length - 1]
      this.countPre++
    },
    // 提交 &保存
    submitLockFrom (val) {
      // const data = {
      //   accountId: this.formData.accountId,
      //   contractBankInfo: {
      //     contractId: this.formData.keyId,
      //     enterpriseId: this.formData.gysId,
      //     fileId: this.formData.fileId,
      //     fileName: this.formData.fileName
      //   },
      //   keyId: this.formData.keyId,
      //   lockStatus: val === 'save' ? '0' : '1'
      // }

      const data = {
        contractLockInfo: {
          account: this.formData.account,
          accountNo: this.formData.accountNo,
          bankName: this.formData.bankName,
          contractId: this.formData.keyId,
          enterpriseId: this.formData.gysId,
          fileId: this.formData.fileId,
          fileName: this.formData.fileName
        },
        keyId: this.formData.keyId,
        lockStatus: val === 'save' ? '0' : '1'
      }
      if (val === 'save') {
        this.saveData(val, data)
      }
      if (val === 'submit') {
        if (!this.formData.keyId || !this.formData.account || !this.formData.bankName || !this.formData.accountNo) {
          this.warning('请选择账户信息')
          return
        }
        if (!data.contractLockInfo.fileName) {
          this.warning('请上传回函文件')
          return
        }
        this.saveData(val, data)
      }
    },
    // 提交保存接口
    saveData (val, data) {
      lockAPi.submitLock(data).then(res => {
        if (res.data) {
          this.success(val === 'save' ? '保存成功' : '提交成功')
          this.visibleState = false
          this.$parent.handleFilter()
        }
      })
    },
    // 取消
    cancel () {
      if (this.disabled) {
        this.disabled = false
      } else {
        this.visibleState = false
      }
    },
    // 获取更改的数据
    getLockData () {
      lockAPi.lockDetail({ contractId: this.contractId }).then(res => {
        this.detailInfo = res.data
        this.detailInfo.contractAmount = res.data.contractAmount ? res.data.contractAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : ''
        this.formData = res.data
        this.enterpriseId = res.data.gysId
        this.fileName = res.data.fileName
        this.fileId = res.data.fileId
        this.count++
      }).catch(err => {
        console.log(err)
      })
    }

  }
}
</script>
<style lang="scss" scoped>
  .lock{
    height: 800px;
  }
  .formStyle{
    /deep/.el-form-item{
      margin-bottom: 30px !important;
    }
    /deep/.el-form-item__label{
    padding-left: 12px !important;
  }
  }

</style>
