import http from '@/utils/http'
import Rest from '@/utils/http/Rest'

export default class asset extends Rest {
  /**
     *付款单信息详情
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  assetInfoDetail (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/detail`,
      params
    })
  }

  /**
     *新增项目合同信息
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  addInfo (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/add`,
      data
    })
  }

  /**
     *修改合同信息
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  updataInfo (data) {
    return http({
      showLoading: true,
      method: 'put',
      url: `/api/${this.subSys}/${this.module}/update`,
      data
    })
  }

  /**
     *删除合同信息
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  delInfo (params) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/delete`,
      params
    })
  }

  /**
     *查看合同信息详情
     * @param params 请求参数
     * @returns {AxiosPromise}
     */
  contractDetail (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/detail`,
      params
    })
  }

  /**
   * 加锁数据详情
   * @param params 参数
   * @returns {AxiosPromise}
   */
  lockDetail (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/detail`,
      params
    })
  }

  /**
     * 加锁履历
     * @param params 参数
     * @returns {AxiosPromise}
     */
  lockHistory (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/history`,
      params
    })
  }

  /**
     * 加锁提交
     * @param params 参数
     * @returns {AxiosPromise}
     */
  submitLock (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/lock`,
      data
    })
  }

  /**
     * 批量解锁 解锁
     * @param params 参数
     * @returns {AxiosPromise}
     */
  batchUnlock (data) {
    return http({
      showLoading: true,
      method: 'put',
      url: `/api/${this.subSys}/${this.module}/unlock?contractIds=${data.contractIds}`,
      data
    })
  }

  /**
     * 查询产值变化总计数据
     * @param data 参数
     * @returns {AxiosPromise}
     */
  outPutPage (data) {
    return http({
      showLoading: true,
      method: 'POST',
      url: `/api/${this.subSys}/${this.module}/page`,
      data
    })
  }

  /**
     * 查询产值变化总计数据
     * @param data 参数
     * @returns {AxiosPromise}
     */
  outPutTotal (data) {
    return http({
      showLoading: true,
      method: 'POST',
      url: `/api/${this.subSys}/${this.module}/total`,
      data
    })
  }

  /**
     * 资产变化明细分页查询
     * @param data 参数
     * @returns {AxiosPromise}
     */
  detailspage (data) {
    return http({
      showLoading: true,
      method: 'POST',
      url: `/api/${this.subSys}/${this.module}/detailspage`,
      data
    })
  }

  /**
     * 查询资产变化明细分总计
     * @param data 参数
     * @returns {AxiosPromise}
     */
  detailstotal (data) {
    return http({
      showLoading: true,
      method: 'POST',
      url: `/api/${this.subSys}/${this.module}/detailstotal`,
      data
    })
  }

  /**
     * 产值变化合同详情
     * @param data 参数
     * @returns {AxiosPromise}
     */
  contractDetails (data) {
    return http({
      showLoading: true,
      method: 'POST',
      url: `/api/${this.subSys}/${this.module}/contractDetails?contractRecordId=${data.contractRecordId}`,
      data
    })
  }

  /**
     * 合同列表
     * @param data 参数
     * @returns {AxiosPromise}
     */

  contractList (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/list`,
      params
    })
  }

  // 根据合同id获取合同和项目信息
  contractByContractIds (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/admittance/contractByContractIds`,
      params
    })
  }

  // 删除付款单合同关联关系
  updateDelStatus (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}/updateDelStatus`,
      data
    })
  }
}
